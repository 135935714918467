/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid, MenuItem } from "@mui/material";
import { useMemo, type ReactElement } from "react";
import {
  mostCommonRulesFields,
  FilterStatisticsCommonFields,
  loadableMostCommonRulesState,
} from "../../../services/filterStatisticsApi";
import { useForm } from "react-hook-form";
import { LoadingButton } from "../../../components/LoadingButton/LoadingButton";
import { useAtom, useSetAtom } from "jotai";
import Error from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import { ControlledTextField } from "../../../components/HookForms/ControlledTextField";
import { config } from "../../../config/AdminUiConfig";
import { TabbedResponseTable } from "../../../components/TabbedResponseTable/TabbedResponseTable";
import { ALL_REGIONS_KEY } from "../../../helpers/AllRegionsKey";

/**
 * MostCommonRules view component to display the most commonly hit filter rules
 * @returns MostCommonRulesView component
 */
export function MostCommonRulesView(): ReactElement {
  /* Result from Filter API listFilters request */
  const [result] = useAtom(loadableMostCommonRulesState);
  /* Atom to dispatch Filter API listFilters request */
  const dispatch = useSetAtom(mostCommonRulesFields);

  /* Form fields for API request */
  const { control, handleSubmit } = useForm<FilterStatisticsCommonFields>({
    defaultValues: {
      region: ALL_REGIONS_KEY,
      numberOfDaysForQuery: 1,
    },
  });

  /* Processed result data to display in TabbedResponseTable */
  const tableData = useMemo(() => {
    /* Map of regions : processed table items */
    const data = new Map();

    if (result.state === "hasData" && result.data) {
      Object.entries(result.data).forEach(([region, response]) => {
        // Map results to table items
        const mappedTableData = response?.filters
          ? response.filters.map((item) => ({ id: item.rule, count: item.count }))
          : [];

        data.set(region, { executionStatus: response.status, items: mappedTableData });
      });
    }

    return data;
  }, [result]);

  return (
    <Container maxWidth="md">
      <Box component="form" noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Card sx={{ width: "100%", mt: 4, pr: 2, pb: 1 }} component="section">
          <Box sx={{ m: 3, mb: 1 }} display="flex" alignItems="center" flexDirection="row" width="100%">
            <Box display="flex" flexDirection="column">
              <Typography variant="h6">Find most commonly hit filter rules</Typography>
              <Typography fontStyle="italic" variant="subtitle2">
                * indicates a required field
              </Typography>
            </Box>
          </Box>
          <CardContent>
            {/* Form grid */}
            <Grid container direction="column">
              <Grid item sm={2}>
                <ControlledTextField
                  sx={{ m: 1 }}
                  id="region-select"
                  select
                  control={control}
                  label="Region"
                  name="region"
                  rules={{
                    required: true,
                  }}
                >
                  {[
                    <MenuItem key={ALL_REGIONS_KEY} value={ALL_REGIONS_KEY}>
                      All regions
                    </MenuItem>,
                    ...config.activeRegions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )),
                  ]}
                </ControlledTextField>
                <ControlledTextField
                  id="days-for-query"
                  control={control}
                  sx={{ m: 1 }}
                  label="Days for query*"
                  name="numberOfDaysForQuery"
                  type="number"
                  rules={{
                    min: { value: 1, message: "Min: 1" },
                    max: { value: 10, message: "Max: 10" },
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Box sx={{ m: 1, mt: 0 }} display="flex" flexDirection="row" justifyContent="flex-end">
            <LoadingButton
              state={result.state}
              onClick={handleSubmit((data) => {
                dispatch({
                  ...data,
                  region: data.region === ALL_REGIONS_KEY ? undefined : data.region,
                });
              })}
              variant="contained"
            >
              Find Rules
            </LoadingButton>
          </Box>
        </Card>
      </Box>

      {/* Results */}
      {result.state === "hasData" && result.data ? (
        <TabbedResponseTable data={tableData} />
      ) : result.state === "hasError" ? (
        <Box display="flex" justifyContent="center">
          <Box display="flex" alignItems="center">
            <Error color="error" sx={{ pr: 1 }} />
            Failed to find most common rules.
          </Box>
        </Box>
      ) : result.state === "loading" ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
    </Container>
  );
}
